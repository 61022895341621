import React from 'react'
import axios from "axios";
import { useState, useEffect } from "react";

function Footer() {
  const [metaData, setMetaData] = useState([]);
  

  const get_meta = async () => {
    console.log("meta_url",window.location.href)
    const data = new FormData();
    data.append("url",window.location.href); // Directly use current URL

    try {
      const response = await axios.post("/pro/seo/get_meta.php", data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      
      setMetaData(response.data); // Set the fetched metadata
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching metadata:", error);
    }
  };
  useEffect(() => {
    get_meta(); // Fetch metadata on component mount
  }, []);
  return (
  <>
  <div className='container'>
  <div dangerouslySetInnerHTML={{ __html: metaData.length>0 && metaData[0].code }} />
 

  </div>
   <>

    <footer className="footer">
      <div >
        <div className="container">
          <div className="row">
            <div className="col-lg-3 width-25 mb-30">
              <h4 className="mb-30 color-gray-1000">Contact </h4>
              <div className="font-md mb-20 color-gray-900">
                <strong className="font-md-bold">Address:</strong> 43-C, DHA Phase 2, Karachi
              </div>
              <div className="font-md mb-20 color-gray-900">
                <strong className="font-md-bold">Phone:</strong> (+92) 111 B2B 1102
              </div>
              <div className="font-md mb-20 color-gray-900">
                <strong className="font-md-bold">E-mail:</strong>{" "}
                <a
                  href="/cdn-cgiail-protection"
                  className="__cf_email__"
                  data-cfemail="bcdfd3d2c8dddfc8fcd9dfd3d191d1ddced7d9c892dfd3d1"
                >
                  support@alahdeen.com
                </a>
              </div>
              <div className="font-md mb-20 color-gray-900">
                <strong className="font-md-bold">Hours:</strong> 8:00 - 17:00, Mon
                - Fri
              </div>
              <div className="mt-30">
                <a className="icon-socials icon-facebook" href="https://facebook.com/alahdeenb2b" />
                <a className="icon-socials icon-instagram" href="https://instagram.com/alahdeenb2b" />
                <a className="icon-socials icon-twitter" href="https://twitter.com/alahdeenb2b" />
                {/* <a className="icon-socials icon-linkedin" href="" /> */}
              </div>
            </div>
            <div className="col-lg-3 width-20 mb-30">
              <h4 className="mb-30 color-gray-1000">Services</h4>
              <ul className="menu-footer">
                  <li>
                    {" "}
                    <a href="/services/logistics.html"> Logistics </a>
                  </li>
                  <li>
                    {" "}
                    <a href="/services/warehouse.html"> Warehouse </a>
                  </li>
                  <li>
                    {" "}
                    <a href="/services/ewallet.html"> E-Wallet </a>
                  </li>
                  <li>
                    {" "}
                    <a href="/services/office.html"> Office Management </a>
                  </li>
                </ul>
            </div>
          
            <div className="col-lg-3 width-16 mb-30">
              <h4 className="mb-30 color-gray-1000"></h4>
              <ul className="menu-footer">
                  <li style={{ marginBottom: "15px" }}>
                    <br />
                  </li>

                  <li>
                    <a href="/services/adon.html"> Advertisement </a>
                  </li>
                  <li>
                    <a href="/services/setup.html">Setup a Business</a>
                  </li>
                  <li>
                    <a href="/services/inventory.html">Inventory</a>
                  </li>
                  <li> <a href="/services/taxation.html">Taxation</a>
                  </li>
                </ul>
            </div>
            <div className="col-lg-3 width-23">
              <h4 className="mb-30 color-gray-1000">App &amp; Payment</h4>
              <div>
                <p className="font-md color-gray-900">
                  Download our Apps and get extra 15% Discount on your first
                  Order…!
                </p>
                <div className="mt-20">
                  <a className="mr-10" href="https://play.google.com/store/apps/details?id=com.alahdin&hl=en">
                    <img src="assetsss/imgs/template/appstore.png" alt="Ecom" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.alahdin&hl=en">
                    <img src="assetsss/imgs/template/google-play.png" alt="Ecom" />
                  </a>
                </div>
               </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-2">
        <div >
          <div className="container">
            <div className="footer-2-top mb-20">
              <a href="/">
                <img alt="Ecom" height={70} src="assets/images/logos/logo.png" />
              </a>
              <a className="font-xs color-gray-1000" href="/">
                Alahdeen.com
              </a>
              <a className="font-xs color-gray-1000" href="/">
                Alahdeen Partners
              </a>
              <a className="font-xs color-gray-1000" href="/">
            Alahdeen Plans
              </a>
              <a className="font-xs color-gray-1000" href="/">
               Alahdeen Leads
              </a>
            </div>
          
          </div>
        </div>
        <div className="container">
          <div className="footer-bottom mt-20">
            <div className="row">
              <div className="col-lg-6 col-md-12 text-center text-lg-start">
                <span className="color-gray-900 font-sm">
                  Copyright © 2024 Alahdeen B2B. All rights reserved.
                </span>
              </div>
              <div className="col-lg-6 col-md-12 text-center text-lg-end">
                <ul className="menu-bottom">
                  <li>
                    <a className="font-sm color-gray-900" href="/">
                      Conditions of Use
                    </a>
                  </li>
                  <li>
                    <a className="font-sm color-gray-900" href="/">
                      Privacy Notice
                    </a>
                  </li>
                  <li>
                    <a
                      className="font-sm color-gray-900"
                      href="/"
                    >
                      Interest-Based Ads
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </>
    
   
  </>
  )
}

export default Footer