import {useState, useEffect} from 'react';
import axios from 'axios';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import Nav from '../../../navbar';
import SweetAlert from 'react-bootstrap-sweetalert';
function Mainpage() {
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [productList, setProductList] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alert3, setAlert3] = useState(false);
  const [prom, setProm] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [pid, setPid] = useState('');
  const [del, setDel] = useState('');

  useEffect(() => {
    const v = JSON.parse(localStorage.getItem('USER'));
    if (v === null) {
    } else {
      setUid(v[0].id);
      viewProduct(v[0].id);
     
    }
  }, []);
  const viewProduct = async id => {
    const data = new FormData();
    data.append('uid', id);

    axios({
      method: 'post',
      url: '/pro/view_product_user.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('product list ', s.data);
        const a = s.data;
       
        setProductList(a);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };


  const productStatus = async (pid, status) => {
    const data = new FormData();
    data.append('pid', pid);
    data.append('status', status);

    axios({
      method: 'post',
      url: '/pro/productbyid.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log(s.data);
        viewProduct(uid);
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  const deleteproduct = async pid => {
    const data = new FormData();

    data.append('pid', pid);
    data.append('val', 'delete');

    axios({
      method: 'post',
      url: '/pro/product_manage.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('status list ', s.data);
        viewProduct(uid);
       
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };


  const Runpromotion = (pid,price) => {
    setPid(pid)
    setProm(price)
    setAlert(true)


  };

  const Promotion = async () => {
    const data = new FormData();
    data.append('type', 'yes');
    data.append('uid', uid);
    data.append('pid', pid);
    data.append('price', prom);
    data.append('start', start);
    data.append('end', end);

    axios({
      method: 'post',
      url: '/pro/leadproductpromostion.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('product list ', s.data);
        
        viewProduct(uid);
      
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };

  const RemPromotion = async (pid) => {
    const data = new FormData();
    data.append('type', 'no');
    data.append('uid', uid);
    data.append('pid', pid);
 

    axios({
      method: 'post',
      url: '/pro/leadproductpromostion.php',
      data: data,
      headers: {'Content-Type': 'multipart/form-data'},
    })
      .then(s => {
        console.log('product list ', s.data);
        
        viewProduct(uid);
      
      })
      .catch(s => {
        console.log('here catch =>>', s);
      });
  };
  return (
    <body>
      <Nav />

      <div className="content-wrap">
        <div className="main">
          <div className="container-fluid">
            {/* <!-- /# column --> */}

            {/* <!-- /# row --> */}
            <section id="main-content">
              {/* <!-- /# column --> */}

              {/* <!-- /# column --> */}
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-title">
                    <h4> Product List</h4>
                  </div>
               
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-hover ">
                        <thead>
                          <tr>
                            <th>ID </th>
                            <th>Featured Image</th>
                            <th>Title</th>
                            <th>Product code</th>
                            <th>Price</th>
                            <th>Category</th>
                            <th>Promotion</th>
                            <th>Status</th>
                            <th>Controls</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productList.length === 0 ? (
                            <>
                              <tr>
                                <td
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                  colSpan={10}>
                                  No Record to show{' '}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {productList.map((v, i) => (
                                <tr>
                                  <td>{v.counter}</td>
                                  <td style={{width: '20%'}}>
                                    <img
                                      style={{width: "120px", height: "120px"}} alt={v.title}
                                      src={v.img.length<=1?"https://alahdeen.com/pro/product_img/image.jpg":v.img}
                                    />
                                  </td>

                                  <td>{v.title}</td>
                                  <td>{v.code}</td>
                                  <td>{v.pro_type== 'yes'?<><p style={{color:"green"}}>{v.pro_price}  <del style={{color:"red"}}>{v.price}</del></p></>:<>{v.price}</>}</td>
                                  <td>{v.catname}</td>
                                  <td style={{color:"blue", cursor:"pointer"}}>
                                    
                                    {v.status=='active' && v.pro_type== 'yes'?<><p onClick={()=>{RemPromotion(v.pid)}} style={{color:"green"}}>Remove Promotion</p></>:v.status=='inactive'?<>Promotion Inactive</>:<p style={{color:"blue"}} onClick={()=>{   
                                    Runpromotion(v.pid,v.price)
                                   }}> <u >  Apply for Promotion</u></p>}
                                    
                                   </td>
                                  <td>
                                    {v.pro_type== "no" && v.status == 'active' ? (
                                      <>
                                        <p
                                          style={{
                                            cursor: 'pointer',
                                            color: 'green',
                                          }}
                                          onClick={() => {
                                            productStatus(v.pid, 'inactive');
                                          }}>
                                          {v.status}
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <p
                                          style={{
                                            cursor: 'pointer',
                                            color: 'red',
                                          }}
                                          onClick={() => {
                                            productStatus(v.pid, 'active');
                                          }}>
                                          {v.status}
                                        </p>
                                      </>
                                    )}
                                  </td>
                                  <td>
                                  
                                  
                                  
                                  
                                    <label
                                     className='mr-1'
                                     style={{color: 'red', cursor: 'pointer'}}
                                      onClick={() => {
                                        localStorage.setItem("ProductEditId",v.pid)
                                       navigate("/productedit")
                                      }}>
                                      Edit 
                                    </label>
                                      |
                                    <label
                                     className='ml-1'
                                      style={{color: 'red', cursor: 'pointer'}}
                                      onClick={() => {
                                        setDel(v.pid)
                                        setAlert3(true)
                                      }}>
                                      Delete
                                    </label>
                                  </td>
                                </tr>
                                
                              ))}
                              
                            </>
                            
                          )}
                            
                        </tbody>
                      </table>

                      {alert3 ? (
                <>
                  
                  <SweetAlert
                    warning
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="success"
                    title="Confirm Delete"
                    allowEscape = {false}
                    onConfirm={() => {
                      deleteproduct(del);
                    
                      setAlert3(!alert3);
                    }}
                    onCancel={() => {
                      setAlert3(!alert3);
                    }}
                    
                    focusCancelBtn
                  >
                    <br />
                  </SweetAlert>
                </>
              ) : (
                <></>
              )}
                      {alert ? (
                                <>
                                  {' '}
                                  <SweetAlert
                                    
                                    confirmBtnText="Confirm"
                                    cancelBtnText="Confirm"
                                    confirmBtnBsStyle="success"
                                    title="Add Promotion"
                                    
                                    onConfirm={() => {
                                      Promotion()
                                      setAlert(!alert);
                                     
                                    }}
                                    onCancel={() => {
                                      setAlert(!alert);
                                    }}
                                    focusCancelBtn>
                                    <br />
                                      <label >Promotion Price </label>
                                    <input value={prom} onChange={(v)=>{setProm(v.target.value)}} className='form-control'/>
                                    <br />
                                    <div className='row'>
                                    <div className='col-6'>
                                      <label>Start Date</label>
                                    <input value={start} onChange={(v)=>{setStart(v.target.value)}} className='form-control' type='date'/>
                                    </div>
                                    <br />
                                    
                                    <div className='col-6'>
                                      <label>End Date</label>
                                    <input  value={end} onChange={(v)=>{setEnd(v.target.value)}} className='form-control' type='date'/>
                                    </div>
                                    </div>
                                    <br/>
                                  </SweetAlert>
                                </>
                              ) : (
                                <></>
                              )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
}
export default Mainpage;
